













import { AsyncView } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Listing } from 'client-website-ts-library/types';
import { Component, Mixins } from 'vue-property-decorator';

import Form from '@/components/Forms/Form.vue';
import { FormConstructorData, FormField } from 'client-website-ts-library/types/Forms';
import { ContextItemType } from 'client-website-ts-library/types/Context';

@Component({
  components: {
    Form,
  },
})
export default class ListingMakeAnOffer extends Mixins(AsyncView) {
  private listing: Listing | null = null;

  private formData: FormConstructorData | null = null;

  mounted() {
    API.Listings.Get(this.$route.params.id).then((listing) => {
      this.listing = listing;

      this.formData = {
        ClientWebsiteId: Config.Website.Id,
        Items: [
          {
            Type: ContextItemType.Listing,
            Id: listing.Id,
          },
        ],
      };
    });
  }

  handleFieldInput(data: FormField) {
    const field = document.getElementById(`field_${data.FieldId}`);

    if (field) {
      if (field instanceof HTMLInputElement) {
        const value = field.value;
        console.log(field.value);
      } else if (field instanceof HTMLSelectElement) {
        if (field.value === 'Yes, there are other offers') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('hidden-offers');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('hidden-offers');
            hiddenField.classList.add('show-offers');
          });
        } else if (field.value === 'No, there are no other offers') {
          // Get all elements with the class "hidden"
          const fieldsHidden = document.getElementsByClassName('show-offers');

          // Use forEach to remove the "hidden" class from each element
          Array.from(fieldsHidden).forEach((hiddenField) => {
            hiddenField.classList.remove('show-offers');
            hiddenField.classList.add('hidden-offers');
          });
        }
      } else {
        console.log('Field type not supported.');
      }
    }
  }
}
